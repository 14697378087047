.job-posting__section{
  overflow-y: scroll !important;
  height: 75vh !important;
}

.job-posting__section .section__title{
  color: var(--green-900);
  font-weight: 600;
  font-size: 1.5rem;
}

.job-post__title {
  font-size: 1.1rem;
  text-decoration: none;
  color: var(--green-500)
}

.job-post__view-link{
  color: var(--green-900)
}
