.public-footer{
  height: 5vh;
  color: var(--surface-500);
  font-size: 0.75rem;
}

.public-footer a {
  text-decoration: none;
  color: var(--green-600);
  font-weight: 500;
}
