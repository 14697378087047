:root {
	--color-primary: #499000;
	--color-primary-dark: #0a1400;
}

::selection {
	color: var(--primary-400);
	background-color: var(--primary-50);
}

html {
	font-size: 14px;
}

body {
	margin: 0;
	font-family: 'Inter', 'Arial', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* Utils */
.btn {
	outline: none;
}

.inter {
	font-family: 'Inter', sans-serif !important;
}

.page__header {
	font-size: 1.25rem;
	font-weight: 500;
}

.required::after {
	display: inline;
	content: '*';
	margin-left: 0.25rem;
	color: var(--red-500);
}

/* Base Component Customization */
::-webkit-scrollbar{
	width: 4px;
}

::-webkit-scrollbar-track{
	background-color: var(--surface-100);
	border-radius: 7px;
}

::-webkit-scrollbar-thumb{
	background-color: var(--primary-900);
	border-radius: 7px;
}

.section__container{
	width: 85%;
	max-width: 1600px;
	margin-inline: auto;
}

.section__title{
	font-weight: 500;
	font-size: 1.25rem;
	margin-block: 0.5rem;
}


/* UnAuthHeader */
.jumbotron{
  height: 15vh;
  background: var(--primary-600) !important;
  color: var(--surface-50)
}

.company-title{
  font-weight: 400;
  font-size: 1.25rem
}

.page-title{
  font-weight: 600;
  font-size: 2.5rem;
}
